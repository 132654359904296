body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.sd-logo__image{
  height: 60px !important;
}
.custom-nps-question{
  margin-left: -156px !important;
width: 153% !important;
}

.sd-container-modern__title{
  display: flex !important;
  flex-direction: column !important; ;
}
/* .sd-title.sd-element__title {
  text-align: end;
} */
.sagar{
  width: 100vw;
  height:100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sagarh2{
  font-size: 35px;
  font-family: 800;
  text-transform: capitalize;

}
.sagarmodal{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 70px;
}

.sagarmodal button {
  position: relative;
  background-color: rgb(230, 34, 77);
  border-radius: 5px;
  box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;
  padding: 15px;
  background-repeat: no-repeat;
  cursor: pointer;
  box-sizing: border-box;
  width: 154px;
  height: 49px;
  color: #fff;
  border: none;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  overflow: hidden;
}

.sagarmodal button::before {
  content: "";
  background-color: rgb(248, 50, 93);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 700ms ease-in-out;
  display: inline-block;
}

.sagarmodal button:hover::before {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .sagarh2{
    font-size: 21px;
  }
  .sagarmodal{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
  }
  .sagarmodal button {
    position: relative;
    background-color: rgb(230, 34, 77);
    border-radius: 5px;
    box-shadow: rgb(121, 18, 55) 0px 4px 0px 0px;
    padding: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
    box-sizing: border-box;
    width: 134px;
    height: 49px;
    color: #fff;
    border: none;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    overflow: hidden;
  }
}
.sd-completedpage{
  height: 100vh !important;
}
